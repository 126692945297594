<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="2" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.image)" class="mb-2" />
							<b-button class="btn-upload btn-block" @click="$refs.filemanager.show('image')">
									<i class="fa fa-upload"></i> Upload Image
							</b-button>
						</div>
					</b-col>
					<b-col sm="5" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('name')}" label="Name">
							<b-form-input v-model="models.name" v-validate="'required'" data-vv-name="name">
							</b-form-input>
							<div v-if="errors.has('name')">
								<p class="text-danger">{{ errors.first('name') }}</p>
							</div>
						</b-form-group>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.keyword"
							placeholder="Meta Keyword"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-group label="Status">
							<div class="col-sm-12 no-padding">
								<label class="radio-inline no-padding">
									<input type="radio" v-model="models.status" value="active">
									<span class="badge badge-success">Active</span>
								</label>
								<label class="radio-inline">
									<input type="radio" v-model="models.status" value="inactive">
									<span class="badge badge-danger">Inactive</span>
								</label>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="5" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('parent')}" label="Parent Category">
							<select v-model="models.parent_id" v-validate="'required'" data-vv-name="parent"
								class="form-control">
								<option value="">Select parent</option>
								<option value="0">None</option>
								<option v-for="(v,i) in masters.categories" :value="v.id" :key="i">
									{{v.name}}
								</option>
							</select>
							<div v-if="errors.has('parent')">
								<p class="text-danger">{{ errors.first('parent') }}</p>
							</div>
						</b-form-group>
						<b-form-checkbox
							id="available"
							class="my-3"
							v-model="models.is_available_contributor"
							name="available"
							:value="true"
							:unchecked-value="false"
						>
							Available at Contributor
						</b-form-checkbox>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.description"
							placeholder="Meta Description"
							rows="5"
							max-rows="10"
						></b-form-textarea>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>

		<Filemanager ref="filemanager" name="news category" @selectMedia="selectMedia"/>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import {
		getUrlOfFile
	} from '@/utils/helpers.js';
	import ResponseAlert from '@/components/response_alert';
	import Filemanager from '@/components/file_manager';


	export default {
		name: 'FormCategory',
		components: {
			ResponseAlert,
			Filemanager
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					name: "",
					parent_id: "",
					is_available_contributor: false,
					image: "",
					meta: {
						description: "",
						keyword: ""
					},
					status: "active",
				},
				masters: {
					categories: [],
				},
				vars: {
					FormData: new FormData(),
					role_password: 'required|min:8'
				}
			}
		},
		created() {
			let _ = this
			_.getById();
			_.getCategory();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.role_password = ''
					_.$axios.get(`${api.news_category}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.name = data.name;
							_.models.parent_id = data.parent_id;
							_.models.is_available_contributor = data.is_available_contributor;
							_.models.image = data.image;
							_.models.meta.description = data.meta.description;
							_.models.meta.keyword = data.meta.keyword;
							_.models.status = data.status;
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getCategory() {
				const _ = this
				_.$axios.get(api.news_category, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
					}
				}).then(res => {
						_.masters.categories = _.$route.params.id ?res.data.data.rows.filter(c => c.id.toString() !== _.$route.params.id.toString()) : res.data.data.rows
					}
				);
			},
			AddPhoto(event) {
				this.models.image_profile = event.target.files[0];
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},
			selectMedia(field, media) {
				const _ = this;
				_.models.image = media.file;
			},
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"

						if(!_.models.image) _.models.image = ''

						if (!_.$route.params.id) {
							request = _.$axios.post(api.news_category, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.news_category}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} news category`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>